<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="updateMode && !disabled && editable" label="작업환경측정결과 업로드" icon="assignment" @btnClicked="openExcelUploader"/>
                <c-btn
                  v-if="popupParam.workMeasurementPlanId && !disabled && editable"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData"/>
                <c-btn
                  v-if="!disabled && updateMode && editable"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="data"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="check"
                  @beforeAction="completeData"
                  @btnCallback="completeCallback" />
                <c-btn
                  v-if="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVEALL"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="측정기관"
                  name="measPlaceName"
                  v-model="data.measPlaceName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  type="year"
                  :disabled="disabled || updateMode"
                  label="측정년도"
                  name="measYear"
                  v-model="data.measYear">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 분기 -->
                <c-select
                  required
                  type="edit"
                  :editable="editable"
                  codeGroupCd="WORK_MEAS_QUARTER_CD"
                  itemText="codeName"
                  :disabled="disabled || updateMode"
                  itemValue="code"
                  name="measQuarter"
                  label="분기"
                  v-model="data.measQuarter"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :editable="editable"
                  type="date"
                  :disabled="disabled"
                  label="측정기간"
                  :range="true"
                  name="measPeriod"
                  v-model="measPeriod">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="측정계획명"
                  name="measPlanContent"
                  v-model="data.measPlanContent">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  suffix="℃"
                  :editable="editable"
                  :disabled="disabled"
                  label="작업장기온"
                  name="measTemperature"
                  v-model="data.measTemperature">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  suffix="%"
                  :editable="editable"
                  :disabled="disabled"
                  label="작업장습도"
                  name="measHumidity"
                  v-model="data.measHumidity">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-hea-checkup
                  :editable="editable"
                  :disabled="disabled"
                  checkupTypeCd="CUTC000002"
                  name="heaCheckupPlanId"
                  v-model="data.heaCheckupPlanId">
                </c-hea-checkup>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab" >
        <component
          :is="tab.component"
          :data.sync="data"
          :disabled="disabled"
          :updateMode="updateMode"
          :tabParam="tabParam"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-measure-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        workMeasurementPlanId: '',
      }),
    },
  },
  data() {
    return {
      mappingType: 'POST',
      saveUrl: transactionConfig.sai.workmeas.plan.insert.url,
      insertUrl: '',
      updateUrl: '',
      completeUrl: transactionConfig.sai.workmeas.plan.complete.url,
      deleteUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isComplete: false,
      data: {
        workMeasurementPlanId: '',
        plantCd: '',
        measPlaceName: '',
        measPlanContent: '',
        measQuarter: null,
        measYear: '',
        measStartDate: '',
        measEndDate: '',
        measCompleteFlag: 'N',
        measTemperature: '',
        measHumidity: '',
        heaCheckupPlanId: '',
        regUserId: '',
        chgUserId: '',

        workMeasurementResultModelList: [],

        updateFlag: '',
      },
      measPeriod: ['', ''],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      measTime: [],
      updateMode: false,
      tab: 'resultInfo1',
      tabItems: [
        { name: 'resultInfo1', icon: 'groups', label: '화학적인자', component: () => import(`${'./workMeasureResultInfo.vue'}`) },
        { name: 'resultInfo2', icon: 'groups', label: '물리적인자', component: () => import(`${'./workMeasureResultInfo.vue'}`) },
        { name: 'resultInfo3', icon: 'groups', label: '분진', component: () => import(`${'./workMeasureResultInfo.vue'}`) },
        { name: 'resultImpr', icon: 'health_and_safety', label: '개선목록', component: () => import(`${'./workMeasureResultImpr.vue'}`) },
      ],
      tabParam: {
        title: '화학적인자',
        hazardClassFstCd: 'HCF0000005'
      }
    };
  },
  computed: {
    disabled() {
      return this.editable && this.data.measCompleteFlag === 'Y'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.workmeas.plan.get.url;
      this.checkUrl = selectConfig.sai.workmeas.plan.check.url;
      this.insertUrl = transactionConfig.sai.workmeas.plan.insert.url;
      this.updateUrl = transactionConfig.sai.workmeas.plan.update.url;
      this.deleteUrl = transactionConfig.sai.workmeas.plan.delete.url;
      this.completeUrl = transactionConfig.sai.workmeas.plan.complete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.workMeasurementPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.workMeasurementPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          if (_result.data.measStartDate && _result.data.measEndDate) {
            this.measPeriod = [this.data.measStartDate, this.data.measEndDate];
          }
          this.$set(this.data, 'updateFlag', uid())
        },);
      } else {
        this.data.measYear = this.$comm.getThisYear();
        if (parseInt(this.$comm.getThisMonth().substring(5,8)) < 7) {
          this.data.measQuarter = 'WMQ0000001'; // 상반기
        } else {
          this.data.measQuarter = 'WMQ0000002'; // 하반기
        }
      }
    },
    saveData() {
      if (this.popupParam.workMeasurementPlanId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.measYear, this.data.measQuarter, this.data.plantCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;

                  if(this.measPeriod) {
                    this.data.measStartDate = this.measPeriod[0];
                    this.data.measEndDate = this.measPeriod[1];
                  }
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '동일한 년도(분기)가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
          
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.workMeasurementPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.workMeasurementPlanId = result.data
      this.getDetail();
    },
    completeData() {
      if (this.data.workMeasurementResultModelList.length > 0) {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGCOMPLETE', // 완료하시겠습니까?,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;

                if(this.measPeriod) {
                  this.data.measStartDate = this.measPeriod[0];
                  this.data.measEndDate = this.measPeriod[1];
                }
                this.isComplete = !this.isComplete;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message:
          '등록된 작업환경 측정결과가 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      }
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.workMeasurementPlanId = result.data
      this.getDetail();
    },
    tabClick(result) {
      if (result.name === 'resultInfo1') {
        this.tabParam.title = '화학적인자';
        this.tabParam.hazardClassFstCd = 'HCF0000005';
      } else if (result.name === 'resultInfo2') {
        this.tabParam.title = '물리적인자';
        this.tabParam.hazardClassFstCd = 'HCF0000001';
      } else {
        this.tabParam.title = '분진';
        this.tabParam.hazardClassFstCd = 'HCF0000010';
      }
    },
    openExcelUploader() {
      this.popupOptions.title = '작업환경측정결과 업로드'; // 즉시개선
      this.popupOptions.param = {
        hazardClassFstCd: this.tabParam.hazardClassFstCd,
        plantCd: this.data.plantCd,
      }
      this.popupOptions.target = () => import(`${'./workMeasureResultExcelUpload.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.workMeasurementPlanId = this.data.workMeasurementPlanId
          item.workMeasurementResultId = uid();  // 일련번호
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = '';  // 수정자 ID
          item.editFlag = 'C';
        })
        this.$http.url = transactionConfig.sai.workmeas.results.insert.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
        },);
      }
    },
  }
};
</script>
